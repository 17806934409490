import Keycloak from 'keycloak-js';

import Config from '../config.json';

const keycloak = new Keycloak({
  clientId: 'mandant-configuration-client',
  realm: 'master',
  url: process.env.REACT_APP_KEYCLOAK_URL || Config.REACT_APP_KEYCLOAK_URL
});

export default keycloak;
