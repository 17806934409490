import { HashRouter as Router, Routes } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { RouteKey, routes } from './utils/router';
import { AppContainer } from './pages/AppContainer';
import GuardedRoute from './utils/router/GuardedRoute';
import ScrollToTop from './utils/ScrollToTop';
import { BehErrorBoundary } from './pages/shared/BehErrorBoundary';
import './global.css';
import { RecoilRoot } from 'recoil';

function App() {
  const { initialized } = useKeycloak();

  return initialized ? (
    <RecoilRoot>
      <Router>
        <ScrollToTop />
        <AppContainer>
          <BehErrorBoundary>
            <Routes>
              <GuardedRoute
                element={routes[RouteKey.HOME].element}
                path={routes[RouteKey.HOME].path}
              ></GuardedRoute>
            </Routes>
          </BehErrorBoundary>
        </AppContainer>
      </Router>
    </RecoilRoot>
  ) : null;
}

export default App;
