import { useKeycloak } from '@react-keycloak/web';
import { useCallback } from 'react';
import {
  FjdButton,
  FjdHeader,
  FjdMenu,
  FjdMenuItem,
  FjdStack,
  FjdFlyout
} from 'fjd-react-components';

import { useProfile } from '../../../hooks/useProfile';

export function Header() {
  const { keycloak } = useKeycloak();
  const { profile } = useProfile();
  const logout = useCallback(() => {
    keycloak?.logout({
      redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT
    });
  }, [keycloak]);

  return (
    <FjdHeader
      logo={
        <>
          <img src="/efastBehLogo.svg" alt={'Logo des Behörden-Clients'} />
        </>
      }
      menu={
        <FjdMenu>
          {keycloak.authenticated && (
            <FjdMenuItem
              appearance="light"
              href={process.env.REACT_APP_DOCS}
              icon="help-outline"
              label={'Abmelden'}
              onClick={logout}
            />
          )}
        </FjdMenu>
      }
      navigation={
        <FjdStack orientation="horizontal">
          {keycloak.authenticated && (
            <FjdFlyout
              flyout={
                <FjdMenu>
                  <FjdMenuItem
                    appearance="light"
                    data-testid="buttonLogout"
                    icon="logout"
                    label={'Abmelden'}
                    onClick={logout}
                  />
                </FjdMenu>
              }
              offset={6}
              placement="bottom-end"
            >
              {keycloak.authenticated && (
                <FjdButton
                  appearance="primary-link"
                  data-testid="user-button"
                  iconLeft="user-avatar"
                  label={`${profile?.firstName ?? '?'} ${
                    profile?.lastName ?? '?'
                  } `}
                />
              )}
            </FjdFlyout>
          )}
        </FjdStack>
      }
    ></FjdHeader>
  );
}
