import { FjdBaseLayout } from 'fjd-react-components';
import { MandantConfiguration } from './configuration/mandant';
import { Header } from './shared/header/Header';

export function MandantLayout() {
  const content = <MandantConfiguration />;

  return (
    <FjdBaseLayout
      footer={<div></div>}
      header={<Header />}
      mainNavigation={<div></div>}
      fullWidthContent
    >
      {content}
    </FjdBaseLayout>
  );
}
