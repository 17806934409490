import { ReactNode, useMemo } from 'react';
import { SWRConfig, SWRConfiguration, useSWRConfig } from 'swr';

import useAlerts from '../hooks/useAlerts';
import { useApi } from '../hooks/useApi';

interface AppContainerProps {
  children: ReactNode;
}

const METHODE = 'GET';

export function AppContainer({ children }: AppContainerProps) {
  const { renderAlertContainer } = useAlerts();
  const { makeRequest } = useApi();
  const { cache } = useSWRConfig();

  const swrConfig = useMemo<Partial<SWRConfiguration>>(
    () => ({
      cache,
      errorRetryCount: 0,
      fetcher: (url, baseUrl?, accept?) =>
        makeRequest(`${url}`, METHODE, baseUrl, accept),
      revalidateOnFocus: false,
      suspense: true
    }),
    [makeRequest, cache]
  );
  return (
    <SWRConfig value={swrConfig}>
      {children}
      {renderAlertContainer()}
    </SWRConfig>
  );
}
