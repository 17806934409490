import Catch from './Error/ErrorHandler';
import { Fragment } from 'react';
import { ErrorComponentBehoerdenServer } from './Error/ErrorComponent';
import { FjdBaseLayout } from 'fjd-react-components';
type Props = {
  children: React.ReactNode;
};

export const BehErrorBoundary = Catch(function BehErrorBoundary(
  props: Props,
  error?: Error
) {
  if (error) {
    return (
      <FjdBaseLayout
        footer={<div></div>}
        header={<div></div>}
        mainNavigation={<div></div>}
        fullWidthContent
      >
        <ErrorComponentBehoerdenServer />
      </FjdBaseLayout>
    );
  } else {
    return <Fragment>{props.children}</Fragment>;
  }
});
