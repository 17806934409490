import { ReactElement } from 'react';
import { MandantLayout } from '../pages/MandantLayout';

export enum RouteKey {
  HOME
}

export interface Route {
  disabled?: boolean;
  element: ReactElement | null;
  path: string;
}

export const routes: {
  [key in RouteKey]: Route;
} = {
  [RouteKey.HOME]: {
    element: <MandantLayout />,
    path: '/'
  }
};
