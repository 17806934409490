import { FjdAlert } from 'fjd-react-components';
import { fjdAlertErrorBackendMsg } from './ErrorCode';

interface ErrorComponentBackendProps {
  errorCode?: string;
}

export function ErrorComponentBehoerdenServer(
  props: ErrorComponentBackendProps
): JSX.Element {
  const errorcodeMsg = props.errorCode
    ? ` (${props.errorCode})`
    : ' (Server nicht erreichbar oder fehlerhaft)';

  return (
    <FjdAlert
      intent="error"
      appearance={'muted'}
      title={fjdAlertErrorBackendMsg.concat(errorcodeMsg)}
      closable={false}
    />
  );
}
