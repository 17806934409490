import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

import useAlerts from './useAlerts';
import { useApi } from './useApi';
import { MandantDto } from '../models/Mandant';
import {
  BEHError,
  conflictErrorMsg,
  ErrorType,
  useAlertErrorMsg
} from '../pages/shared/Error/ErrorCode';
import keycloak from '../utils/keycloak';

export function useMandant() {
  const { alert } = useAlerts();
  const { makeRequest } = useApi();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<BEHError | undefined>(undefined);

  const createMandant = useCallback(
    async (mandant: MandantDto) => {
      setLoading(true);
      mandant.mandant = `beh-${mandant.mandant}`;
      try {
        await makeRequest<AxiosResponse>(
          `/api/v1/mandant`,
          'POST',
          undefined,
          undefined,
          undefined,
          mandant
        );
        // refresh token. This is needed because after realm is created there are claims specificially for this new realm, and we need them to send invitations
        keycloak.updateToken(1800).then(
          // 30min (session-timeout in keycloak)
          async (refreshed: boolean) => {
            if (refreshed) {
              await makeRequest<AxiosResponse>(
                `/api/v1/mandant/user`,
                'POST',
                undefined,
                undefined,
                undefined,
                mandant
              );
            } else {
              await makeRequest<AxiosResponse>(
                `/api/v1/mandant`,
                'DELETE',
                undefined,
                undefined,
                undefined,
                mandant
              );
            }
          }
        );
        alert('success', 'Der Mandant wurde erfolgreich erstellt!');
        setLoading(false);
        setError(undefined);
        return;
      } catch (e) {
        const behError = e as BEHError;
        switch (behError.type) {
          case ErrorType.BEHOERDENCLIENT_PROBLEMS_MANDANT_CONFLICT:
            alert('error', conflictErrorMsg, 10000, true);
            break;
          case ErrorType.CONSTRAINT_VIOLATION:
            setError(behError);
            break;
          default:
            alert(
              'error',
              useAlertErrorMsg.concat(` (${behError?.traceId})`),
              10000,
              true
            );
        }
      }
      setLoading(false);
    },
    [alert, makeRequest]
  );

  return { createMandant, loading, error };
}
