import useSWR from 'swr';
import { useKeycloak } from '@react-keycloak/web';

export function useProfile() {
  const { keycloak } = useKeycloak();

  const { data: profile, error } = useSWR(
    keycloak.authenticated ? '/profile' : null,
    keycloak.loadUserProfile
  );

  return { profile, error };
}
