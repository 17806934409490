import {
  FjdCard,
  FjdButton,
  FjdStack,
  FjdTextInput,
  FjdSpinner,
  FjdFormControl
} from 'fjd-react-components';
import React, { useState } from 'react';
import { useMandant } from '../../hooks/useMandant';
import './mandant.css';
import { getRedirectUri } from '../../utils/mandant';
import { BEHError } from '../shared/Error/ErrorCode';

export function MandantConfiguration() {
  const [email, setEmail] = useState('');
  const [mandant, setMandant] = useState('');
  const { createMandant, loading, error } = useMandant();

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const isInputValid = (): boolean => {
    if (email === '') {
      return false;
    }

    if (mandant === '') {
      return false;
    }

    if (!isValidEmail(email)) {
      return false;
    }
    return true;
  };

  if (loading) {
    return <FjdSpinner />;
  }

  const getViolationsFor = (field: string, error?: BEHError) => {
    let message = '';
    error?.violations.forEach((violation) => {
      if (violation.field === field) {
        message = message + violation.message;
      }
    });
    return message;
  };

  return (
    <div className={'cardMandant'}>
      <FjdCard backgroundColor="#fafafa">
        <div className={'cardTitle'}>Neuen Mandanten anlegen</div>
        <div>
          Sie können jeden Mandantennamen wählen, der noch nicht vergeben ist,
          weniger als 100 Zeichen und nur aus “a-z”, “A-Z”, ”0-9”, “-” und/oder
          “_” besteht.
        </div>
        <br />
        <FjdStack orientation={'vertical'}>
          <FjdFormControl
            inputId="mandantToRegister"
            label="Neuen Mandanten anlegen"
            validationMessage={getViolationsFor('mandant', error)}
          >
            <FjdTextInput
              id="mandantToRegister"
              type="text"
              value={mandant}
              minLength={1}
              maxLength={100}
              onChange={(event) => setMandant(event.target.value)}
            />
          </FjdFormControl>
          <FjdFormControl
            inputId="emailToRegister"
            label="E-Mail der Administrator:in"
          >
            <FjdTextInput
              id="emailToRegister"
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FjdFormControl>
          <FjdButton
            appearance={'default'}
            size={'s'}
            label={'Anlegen'}
            disabled={!isInputValid()}
            testId={'create_mandant_btn'}
            onClick={() =>
              createMandant({
                mandant: mandant,
                email: email,
                redirectUri: getRedirectUri(mandant)
              })
            }
            id={'create_mandant_btn'}
          />
        </FjdStack>
      </FjdCard>
    </div>
  );
}
