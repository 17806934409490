export enum ErrorType {
  BEHOERDENCLIENT_PROBLEMS_MANDANT_CONFLICT = 'behoerdenclient/problems/mandantConflict',
  CONSTRAINT_VIOLATION = 'https://zalando.github.io/problem/constraint-violation'
}

export enum StatusCode {
  CONFLICT = 409
}

export class BEHError extends Error {
  type: string;
  status: string | undefined;
  detail: string;
  traceId: string | undefined;
  title: string;
  violations: Array<any>;

  constructor(
    type: string,
    msg: string,
    status: string | undefined,
    detail: string,
    traceId: string | undefined,
    title: string,
    violations: Array<any>
  ) {
    super(msg);
    this.type = type;
    this.status = status;
    this.detail = detail;
    this.traceId = traceId;
    this.title = title;
    this.violations = violations;
  }
}

const errorMsgBackend =
  'Bitte wenden Sie sich mit folgendem Error-Code an Ihren Administrator.';

const errorMsgClient = 'Bitte wenden Sie sich an Ihren Administrator';

export const fjdAlertErrorBackendMsg = 'Es ist ein Fehler aufgetreten. '.concat(
  errorMsgBackend
);

export const useAlertErrorMsg = 'Fehler. '.concat(errorMsgBackend);

export const conflictErrorMsg =
  'Dieser Mandanten-Name ist bereits vergeben. Bitte wählen Sie einen anderen Namen.';

const fjdAlertErrorClientMsg = `Es ist ein Fehler aufgetreten. ${errorMsgClient}`;

export function getErrorMsgClient(): string {
  return `${fjdAlertErrorClientMsg}.`;
}
