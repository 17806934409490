import Config from '../config.json';

function getFqdn(): string {
  return window.location.hostname;
}

function removeFirstSubdomainPrefix(partialFqdn: string): string {
  const regex: RegExp = /^[a-z\d]+-/;

  if (regex.test(partialFqdn)) {
    partialFqdn = partialFqdn.split('-')[1];
  }

  return partialFqdn;
}

export function getApiServerUrl(): string {
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  } else if (
    Config.REACT_APP_API_URL &&
    Config.REACT_APP_API_URL !== 'ENV_'.concat('API_URL')
  ) {
    return Config.REACT_APP_API_URL;
  } else {
    return `${window.location.protocol}//api-${removeFirstSubdomainPrefix(
      getFqdn()
    )}`;
  }
}

export function getRedirectUri(mandant: string): string {
  if (process.env.REACT_APP_CLIENT_URL) {
    return `${process.env.REACT_APP_CLIENT_URL}/${mandant}`;
  } else if (
    Config.REACT_APP_CLIENT_URL &&
    Config.REACT_APP_CLIENT_URL !== 'ENV_'.concat('CLIENT_URL')
  ) {
    return `${Config.REACT_APP_CLIENT_URL}/${mandant}`;
  } else {
    return `${window.location.protocol}//${removeFirstSubdomainPrefix(
      getFqdn()
    )}/${mandant}`;
  }
}
