import axios, { Method, ResponseType, AxiosError } from 'axios';
import { useCallback } from 'react';
import { BEHError } from '../pages/shared/Error/ErrorCode';
import { useKeycloak } from '@react-keycloak/web';
import { getApiServerUrl } from '../utils/mandant';

interface RequestOptions {
  onUploadProgress?: (progressEvent: ProgressEvent) => void;
  responseType?: ResponseType;
}

export function useApi() {
  const { keycloak } = useKeycloak();
  const apiUrl = getApiServerUrl();

  const makeRequestWithFullResponse = useCallback(
    async <T>(
      url: string,
      method: Method,
      baseUrl?: string,
      accept?: string,
      contentType?: string,
      data?: any,
      options?: RequestOptions
    ) => {
      const endpointBaseUrl: string = baseUrl
        ? `${baseUrl}${encodeURI(url)}`
        : `${apiUrl}${encodeURI(url)}`;
      const headers = {
        Authorization: keycloak.token ? `Bearer ${keycloak.token}` : undefined,
        'Content-Type': contentType
          ? contentType
          : data
          ? data instanceof FormData
            ? 'multipart/form-data'
            : 'application/json'
          : undefined,
        Accept:
          accept !== undefined && accept !== null ? accept : 'application/json'
      };
      const response = await axios
        .request<T>({
          data,
          onUploadProgress: options?.onUploadProgress,
          headers: headers,
          method,
          responseType: options?.responseType || 'json',
          url: endpointBaseUrl
        })
        .catch((reason: AxiosError) => {
          throw new BEHError(
            reason.response?.data.type,
            reason.response?.data.message,
            reason.response?.data.status,
            reason.response?.data.detail,
            reason.response?.data?.traceId,
            reason.response?.data.title,
            reason.response?.data.violations
          );
        });
      return response;
    },
    [keycloak.token, apiUrl]
  );

  const makeRequest = useCallback(
    async <T>(
      url: string,
      method: Method,
      baseUrl?: string,
      accept?: string,
      contentType?: string,
      data?: any,
      options?: RequestOptions
    ) => {
      return (
        await makeRequestWithFullResponse<T>(
          url,
          method,
          baseUrl,
          accept,
          contentType,
          data,
          options
        )
      ).data;
    },
    [makeRequestWithFullResponse]
  );

  return { makeRequest, makeRequestWithFullResponse };
}
